import React from 'react';
import industripemerintah from '../../assets/images/ckbassetbanner/industripemerintah.webp';
import industripublic from '../../assets/images/ckbassetbanner/industripublic.webp';
import industriswasta from '../../assets/images/ckbassetbanner/industriswasta.webp';

function IndustryBanner({ image, title, description }) {
    return (
        <div className="col-lg-4 ranah-industri">
            <div
                className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
            >
                <div className="content">
                    <div className="images">
                        <img src={image} alt="" />
                    </div>
                    <div className="desc">
                        <h4 className="title">{title}</h4>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Industries({ className }) {
    const industryData = [
        {
            image: industripublic,
            title: 'PUBLIC',
            description:
                'Teknologi biometrik memastikan pengalaman aman dan efisien bagi pengguna dengan identifikasi sidik jari dan wajah. Ini mengurangi risiko pencurian akun dan menjamin keamanan data pribadi melalui teknologi keamanan siber yang kuat.',
        },
        {
            image: industripemerintah,
            title: 'LEMBAGA PEMERINTAH',
            description:
                'Integrasi solusi keamanan biometrik dan siber memungkinkan layanan online pemerintah yang aman dan efisien, seperti permohonan paspor dengan identifikasi wajah/sidik jari. Sistem siber yang kuat melindungi data sensitif dan operasi penting.',
        },
        {
            image: industriswasta,
            title: 'LEMBAGA/PERUSAHAAN SWASTA',
            description:
                'Teknologi biometrik dan keamanan siber melindungi informasi pelanggan dan bisnis. Identifikasi cepat dengan sidik jari/wajah dan perlindungan data menjaga hubungan yang aman dan efisien dengan pelanggan dan klien.',
        },
    ];

    return (
        <section
            className={`appie-download-3-area area-industri pt-100 ${className || ''}`}
            id="download"
        >
            <div className="overlay-item">
                <div className="overlay-left">Industri</div>
                <div className="overlay-right">
                    <span className="pr-2">Lihat Lebih Lengkap</span>{' '}
                    <i className="fal fa-arrow-circle-right "></i>
                </div>
            </div>
            <div className="container-fluid">
                <div className="d-flex industries">
                    <div className="appie-section-title text-start  pr-5">
                        <h3 className="appie-title">Ranah Industri Pengguna</h3>
                        <h5>Solusi Perusahaan</h5>
                    </div>
                    <div className="title-desc">
                        <p>
                            Pelanggan kami, termasuk perusahaan besar, start-up, dan organisasi
                            pemerintah, mengandalkan keamanan kami untuk melindungi data penting
                            mereka.
                        </p>
                    </div>
                </div>
                <div className="row">
                    {industryData.map((data) => (
                        <IndustryBanner key={data.title} {...data} />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Industries;
