import React from 'react';

function HeroAbout() {
    return (
        <>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="d-flex justify-content-start mb-5 tag-about">
                        <div className="overlay-tag">Tentang Kami</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-50-percent">
                            <h3 className="title">
                                PT. Cahaya <br /> Keberuntungan Bersama
                            </h3>
                        </div>
                        <div className="w-50-percent">
                            <p>
                                Kami berkomitmen untuk menyinari setiap langkah Anda dalam bisnis
                                dengan integritas, mencari keunggulan dalam setiap tindakan,
                                berinovasi untuk relevansi, dan mendedikasikan diri untuk kesuksesan
                                bersama.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
