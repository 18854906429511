import React from 'react';
import adint from '../../assets/images/ckbproduct/adint.webp';
import catalog1 from '../../assets/images/ckbproduct/catalog1.webp';
import counterintel from '../../assets/images/ckbproduct/counterinteligence.webp';
import cybercel from '../../assets/images/ckbproduct/cybercellular.webp';
import cyberintel from '../../assets/images/ckbproduct/cyberinteligence.webp';
import cybersecurity from '../../assets/images/ckbproduct/cybersecurity.webp';
import datafusion from '../../assets/images/ckbproduct/datafusion.webp';
import dataveilance from '../../assets/images/ckbproduct/dataveilance.webp';
import geoint from '../../assets/images/ckbproduct/geoint.webp';
import osint from '../../assets/images/ckbproduct/osint.webp';
import signt from '../../assets/images/ckbproduct/signt.webp';
import techsurveil from '../../assets/images/ckbproduct/technicalsurveilance.webp';
import ServiceCard from './ServiceCard'; // Import the ServiceCard component

const serviceData = [
    {
        title: 'Big Data Intelligence',
        description:
            'BIG-DATA Intelligence adalah platform canggih untuk mengumpulkan, analisis data besar. Dengan teknologi terkini, produk ini memberikan wawasan dalam pengambilan keputusan.',
        image: catalog1,
    },
    {
        title: 'Dataveillance',
        description:
            'Dataveillance: perangkat lunak canggih untuk pengumpulan, analisis, dan pemantauan data besar. Solusi andal ini membantu organisasi dalam pengawasan dan pemahaman data dari berbagai sumber.',
        image: dataveilance,
    },
    {
        title: 'Signal Intelligence (SIGINT)',
        description:
            'SIGINT adalah platform canggih untuk mengumpulkan dan analisis sinyal elektronik dari berbagai sumber, memberikan wawasan mendalam dari data tersembunyi dalam sinyal.',
        image: signt,
    },
    {
        title: 'Geospatial Intelligence (GEOINT)',
        description:
            'GEOINT adalah platform canggih untuk mengumpulkan, analisis, dan memahami data geografis dari berbagai sumber, menghubungkan informasi geografis dengan wawasan bisnis dan strategi.',
        image: geoint,
    },
    {
        title: 'Advertising Intelligence (ADINT)',
        description:
            'ADINT adalah platform untuk mengumpulkan, analisis, dan mendapatkan wawasan dari data iklan dan pemasaran, membantu Anda mengukur efektivitas kampanye dan memahami perilaku konsumen.',
        image: adint,
    },
    {
        title: 'Open Source Intelligence (OSINT)',
        description:
            'OSINT adalah platform untuk mengumpulkan data dari berbagai sumber terbuka di internet, seperti situs web, media sosial, dan berita. Produk ini membantu Anda menggali wawasan dari informasi terbuka.',
        image: osint,
    },
    {
        title: 'Cyber Cellular',
        description:
            'Cyber Cellular adalah platform yang melindungi komunikasi seluler dengan enkripsi, pemantauan aktivitas mencurigakan, dan perlindungan Wi-Fi. Produk ini menjaga privasi dan keamanan komunikasi seluler Anda.',
        image: cybercel,
    },
    {
        title: 'Cyber Intelligence',
        description:
            'Cyber Intelligence: mengumpulkan, analisis, dan interpretasi data ancaman siber. Dengan teknologi terkini, membantu identifikasi, penanganan, dan pencegahan serangan yang membahayakan data bisnis.',
        image: cyberintel,
    },
    {
        title: 'Data Fusion and Analysis',
        description:
            'Data Fusion & Analysis: menggabungkan dan analisis data dari berbagai sumber dengan teknologi canggih. Produk ini mengubah data mentah menjadi informasi berharga untuk pengambilan keputusan yang cerdas.',
        image: datafusion,
    },
    {
        title: 'Counter Intelligence',
        description:
            'Counter Intelligence: platform identifikasi, analisis, dan tangani ancaman mata-mata dengan teknologi canggih. Produk ini melindungi informasi sensitif dan strategi bisnis dari peretasan dan pengintaian.',
        image: counterintel,
    },
    {
        title: 'Cyber Security',
        description:
            'Cyber Security: platform melindungi data, jaringan, dan infrastruktur IT dengan teknologi canggih. Produk ini menjaga keberlanjutan bisnis Anda dari ancaman keamanan siber.',
        image: cybersecurity,
    },
    {
        title: 'Technical Surveillance',
        description:
            'Produk Technical Surveillance adalah platform khusus yang melindungi organisasi dari aktivitas pengintaian teknis dengan teknologi mutakhir untuk mendeteksi perangkat dan metode pengintai potensial.',
        image: techsurveil,
    },
];

function ServiceHomeFive({ className }) {
    return (
        <>
            <section className={`appie-services-2-area ${className}`} id="service">
                <div className="container-fluid">
                    <div className="row">
                        {serviceData.map((service, index) => (
                            <ServiceCard key={service.title} {...service} index={index} />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServiceHomeFive;
