import React from 'react';

function ServiceCard({ title, description, image, index }) {
    return (
        <div className="col-lg-4 col-md-6">
            <div
                className="appie-single-service-2 mt-30 wow animated fadeInUp relative"
                data-wow-duration="2000ms"
                data-wow-delay={`200ms * ${index}`}
            >
                <div className="service-desc">
                    <span className="overlay-text">{index + 1}</span>
                    <h4 className="title">{title}</h4>
                    <p>{description}</p>
                </div>
                <div className="icon">
                    <img src={image} alt="" />
                </div>
            </div>
        </div>
    );
}

export default ServiceCard;
