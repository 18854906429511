import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logockb.svg';

function FooterHomeFive() {
    return (
        <>
            <section className="appie-footer-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget footer-about-widget-5">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <h4>PT. Cahaya Keberuntungan Bersama</h4>
                                <p>
                                    Podomoro City Ruko Garden Shopping Arcade Blok B 8 – D/H Jalan
                                    S. Parman, Jakarta Barat - DKI Jakarta
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-navigation footer-navigation-5">
                                <h4 className="title">Product</h4>
                                <div className="row">
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="/big-data-intelligence">
                                                    Big Data Inteligence
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/data-veilance">Data Veilance</Link>
                                            </li>
                                            <li>
                                                <Link to="/signt">SIGNT</Link>
                                            </li>
                                            <li>
                                                <Link to="/geoint">GEOINT</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="/adint">ADINT</Link>
                                            </li>
                                            <li>
                                                <Link to="/industri">Industri</Link>
                                            </li>
                                            <li>
                                                <Link to="/solusi">Solusi</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
                            >
                                <div className="copyright-text text-left">
                                    <p>
                                        © 2023, PT. Cahaya Keberuntungan Bersama, All Right Reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeFive;
