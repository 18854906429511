import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <Link to="/">Beranda</Link>
                </li>
                <li>
                    <Link to="/big-data-intelligence">Produk</Link>
                </li>
                <li>
                    <Link to="/industri">Industri</Link>
                </li>
                <li>
                    <Link to="/solusi">Solusi</Link>
                </li>
                <li>
                    <Link to="/tentang-kami">Tentang Kami</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
