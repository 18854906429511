import React from 'react';
import signt from '../../assets/images/ckbproduct/signt.png';

function ProductSignt() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={signt} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">03</span>
                                            <h1>SIGNAL INTELLIGENCE (SIGINT)</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Signals
                                                Intelligence (SIGINT) kami. Produk ini merupakan
                                                solusi terdepan yang kami tawarkan untuk
                                                memungkinkan Anda memahami, mengumpulkan, dan
                                                menganalisis informasi yang terkandung dalam sinyal
                                                dan komunikasi elektronik. Mari kita jelaskan lebih
                                                lanjut tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Apa itu Produk Signals Intelligence (SIGINT)?</h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Signals Intelligence (SIGINT) adalah
                                                    platform yang dirancang khusus untuk
                                                    mengumpulkan dan menganalisis sinyal elektronik
                                                    dari berbagai sumber, termasuk komunikasi radio,
                                                    satelit, dan jaringan komputer. Dengan
                                                    menggunakan teknologi mutakhir, produk SIGINT
                                                    membantu Anda mendapatkan wawasan mendalam dari
                                                    data yang tersembunyi di dalam sinyal.
                                                </p>
                                                <p>
                                                    Produk Signals Intelligence (SIGINT) kami adalah
                                                    alat penting untuk memahami dunia komunikasi dan
                                                    data elektronik. Dengan menganalisis sinyal dan
                                                    informasi elektronik, Anda dapat mendapatkan
                                                    wawasan yang kuat untuk mendukung keputusan
                                                    bisnis, mengidentifikasi potensi ancaman, dan
                                                    mengungkap peluang baru. Jika Anda mencari cara
                                                    untuk mengoptimalkan pemahaman Anda tentang
                                                    lingkungan digital yang kompleks, produk kami
                                                    adalah solusi yang tepat untuk Anda.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Fitur Utama Produk Signals Intelligence (SIGINT):
                                            </h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pengumpulan Sinyal: Produk kami memungkinkan
                                                        Anda untuk mengumpulkan dan menganalisis
                                                        sinyal elektronik dari berbagai sumber yang
                                                        berbeda, termasuk komunikasi nirkabel dan
                                                        perangkat terkoneksi.
                                                    </li>
                                                    <li>
                                                        Analisis Teknis: Dengan algoritma analisis
                                                        yang canggih, produk SIGINT membantu
                                                        mengurai informasi dari sinyal yang
                                                        kompleks, mengidentifikasi pola dan struktur
                                                        yang relevan.
                                                    </li>
                                                    <li>
                                                        Pemantauan Real-Time: Kami menyediakan
                                                        kemampuan pemantauan sinyal secara
                                                        real-time, memungkinkan Anda mendeteksi
                                                        aktivitas dan komunikasi yang mencurigakan.
                                                    </li>
                                                    <li>
                                                        Integrasi Data: Produk ini dapat
                                                        mengintegrasikan data SIGINT dengan data
                                                        lain, memberikan gambaran yang lebih lengkap
                                                        tentang lingkungan digital.
                                                    </li>
                                                    <li>
                                                        Keamanan dan Enkripsi: Keamanan data adalah
                                                        prioritas utama. Produk kami memiliki fitur
                                                        enkripsi yang kuat untuk melindungi
                                                        informasi sensitif dari ancaman cyber.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Manfaat Produk Signals Intelligence (SIGINT):</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pemahaman yang Lebih Mendalam: Dengan
                                                        menganalisis sinyal elektronik, Anda dapat
                                                        memahami lebih dalam tentang komunikasi dan
                                                        aktivitas yang terjadi.
                                                    </li>
                                                    <li>
                                                        Deteksi Ancaman: Produk ini membantu Anda
                                                        mendeteksi potensi ancaman dan aktivitas
                                                        mencurigakan yang mungkin melibatkan
                                                        komunikasi rahasia.
                                                    </li>
                                                    <li>
                                                        Intelijen Kompetitif: Dengan wawasan dari
                                                        sinyal elektronik, Anda dapat
                                                        mengidentifikasi tren pasar, taktik pesaing,
                                                        dan peluang baru.
                                                    </li>
                                                    <li>
                                                        Keputusan yang Dukungan: Produk SIGINT
                                                        memberikan informasi yang berharga untuk
                                                        pengambilan keputusan yang lebih baik dan
                                                        terinformasi.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductSignt;
