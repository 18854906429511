// import React, { useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import Drawer from '../Mobile/Drawer';
import MenuProduct from '../Product/MenuProduct';
import AboutCkb from './AboutCkb';
import ContactForms from './ContactForm';
import FooterHomeFive from './FooterHomeFive';
import HeroHomeFive from './HeroHomeFive';
import Industries from './Industries';
import ServiceHomeFive from './ServiceHomeFive';
import WhyChooseUs from './WhyChooseUs';

function HomeFive() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <HeroHomeFive />
            <MenuProduct />
            <ServiceHomeFive />
            <Industries className="pb-90" />
            <WhyChooseUs />
            <AboutCkb />
            <ContactForms />
            <FooterHomeFive />
            <BackToTop className="back-to-top-5" />
        </>
    );
}

export default HomeFive;
