import React from 'react';
import cybercel from '../../assets/images/ckbproduct/cybercel.png';

function ProductCyberCellular() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={cybercel} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">07</span>
                                            <h1>CYBER CELLULAR</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Cyber Cellular
                                                kami. Produk ini adalah solusi unggulan yang kami
                                                tawarkan untuk memberikan perlindungan keamanan yang
                                                kuat terhadap komunikasi seluler Anda. Dengan
                                                ancaman keamanan siber yang semakin meningkat,
                                                produk ini dirancang untuk menjaga data dan
                                                informasi sensitif Anda tetap aman dalam lingkungan
                                                yang semakin terhubung. Mari kita lihat lebih detail
                                                tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Apa itu Produk Cyber Cellular?</h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Cyber Cellular adalah platform yang
                                                    diciptakan khusus untuk melindungi komunikasi
                                                    seluler Anda dari potensi risiko dan ancaman
                                                    keamanan siber. Dengan menyediakan enkripsi yang
                                                    kuat, pemantauan aktivitas yang mencurigakan,
                                                    dan perlindungan terhadap jaringan Wi-Fi tidak
                                                    aman, produk kami membantu Anda menjaga privasi
                                                    dan keamanan saat berkomunikasi melalui
                                                    perangkat seluler Anda.
                                                </p>
                                                <p>
                                                    Produk Cyber Cellular kami adalah pendamping
                                                    andal untuk menjaga keamanan komunikasi seluler
                                                    Anda. Dengan perlindungan menyeluruh terhadap
                                                    risiko keamanan siber dan privasi yang dijaga,
                                                    Anda dapat berkomunikasi dengan tenang dan
                                                    percaya diri. Jika Anda mengutamakan keamanan
                                                    dalam komunikasi seluler Anda, produk kami
                                                    adalah solusi yang tepat.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Fitur Utama Produk Cyber Cellular:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Enkripsi Komunikasi: Produk kami menyediakan
                                                        enkripsi tingkat tinggi untuk pesan dan
                                                        panggilan seluler Anda, memastikan informasi
                                                        Anda hanya dapat diakses oleh penerima yang
                                                        dituju.
                                                    </li>
                                                    <li>
                                                        Deteksi Ancaman: Dengan teknologi deteksi
                                                        canggih, produk Cyber Cellular membantu
                                                        mengidentifikasi ancaman keamanan siber yang
                                                        mungkin mengintai perangkat seluler Anda.
                                                    </li>
                                                    <li>
                                                        Keamanan Wi-Fi: Kami menawarkan perlindungan
                                                        terhadap risiko yang terkait dengan koneksi
                                                        Wi-Fi tidak aman, menjaga data Anda aman
                                                        bahkan saat terhubung ke jaringan publik.
                                                    </li>
                                                    <li>
                                                        Pemantauan Aktivitas: Produk ini
                                                        memungkinkan Anda memantau aktivitas yang
                                                        mencurigakan pada perangkat seluler Anda,
                                                        memberi peringatan dini terhadap potensi
                                                        ancaman.
                                                    </li>
                                                    <li>
                                                        Proteksi Aplikasi: Dengan fitur proteksi
                                                        aplikasi, produk kami mencegah instalasi dan
                                                        eksekusi aplikasi berbahaya pada perangkat
                                                        Anda.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Manfaat Produk Cyber Cellular:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Keamanan Komunikasi: Dengan enkripsi dan
                                                        perlindungan yang kuat, komunikasi seluler
                                                        Anda tetap pribadi dan aman.
                                                    </li>
                                                    <li>
                                                        Perlindungan Data: Produk ini membantu
                                                        menjaga kerahasiaan informasi sensitif Anda,
                                                        mencegah akses yang tidak sah.
                                                    </li>
                                                    <li>
                                                        Pencegahan Ancaman: Dengan kemampuan
                                                        mendeteksi dan mencegah ancaman, Anda dapat
                                                        menghindari potensi serangan keamanan.
                                                    </li>
                                                    <li>
                                                        Privasi yang Dijaga: Dalam era digital yang
                                                        terkoneksi, produk kami membantu menjaga
                                                        privasi Anda saat menggunakan perangkat
                                                        seluler.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductCyberCellular;
