import React from 'react';
import bigdataintel from '../../assets/images/ckbproduct/bigdatainteligence.png';

function PageProduct() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={bigdataintel} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">01</span>
                                            <h1>BIG DATA INTELLIGENCE</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk BIG-DATA
                                                Intelligence kami. Produk ini merupakan solusi
                                                terdepan yang kami tawarkan untuk membantu Anda
                                                mengatasi tantangan data besar dan mengambil manfaat
                                                maksimal dari potensi data yang Anda miliki. Mari
                                                kita jelaskan lebih rinci tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Apa itu Produk BIG-DATA Intelligence?</h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk BIG-DATA Intelligence adalah platform
                                                    canggih yang didesain untuk membantu organisasi
                                                    mengumpulkan, menganalisis, dan mengambil
                                                    wawasan berharga dari data dalam skala besar.
                                                    Dengan memanfaatkan teknologi mutakhir dan
                                                    algoritma analitik terdepan, produk ini
                                                    memberikan Anda pandangan yang mendalam tentang
                                                    data Anda, membantu pengambilan keputusan yang
                                                    lebih baik, dan mengidentifikasi peluang yang
                                                    mungkin tidak terlihat sebelumnya. Produk
                                                    BIG-DATA Intelligence kami adalah kunci untuk
                                                    menggali potensi data besar Anda. Dengan
                                                    kemampuan untuk menganalisis, memahami, dan
                                                    mengambil manfaat dari data dalam skala besar,
                                                    Anda dapat mengambil langkah berdasarkan wawasan
                                                    yang kuat, membuat keputusan yang lebih
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Fitur Utama Produk BIG-DATA Intelligence:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pengumpulan Data Skala Besar: Produk kami
                                                        memungkinkan Anda mengumpulkan dan
                                                        mengintegrasikan data dari berbagai sumber
                                                        yang berbeda, memberikan gambaran menyeluruh
                                                        tentang bisnis Anda.
                                                    </li>
                                                    <li>
                                                        Analisis Cerdas: Dengan kecerdasan buatan
                                                        dan teknik analisis canggih, produk ini
                                                        membantu Anda mengurai data yang kompleks
                                                        dan mengidentifikasi pola, tren, dan
                                                        hubungan penting.
                                                    </li>
                                                    <li>
                                                        Visualisasi Interaktif: Kami menyediakan
                                                        visualisasi data yang interaktif dan
                                                        intuitif, membuat wawasan data lebih mudah
                                                        dimengerti dan ditemukan.
                                                    </li>
                                                    <li>
                                                        Pemodelan Prediktif: Produk BIG-DATA
                                                        Intelligence memungkinkan Anda membuat model
                                                        prediksi berdasarkan data historis, membantu
                                                        Anda meramalkan tren dan hasil masa depan.
                                                    </li>
                                                    <li>
                                                        Optimisasi Bisnis: Dengan menganalisis data
                                                        operasional, Anda dapat mengidentifikasi
                                                        peluang untuk meningkatkan efisiensi dan
                                                        mengoptimalkan proses bisnis.
                                                    </li>
                                                    <li>
                                                        Keamanan Data: Keamanan data adalah
                                                        prioritas utama. Produk kami menyediakan
                                                        fitur keamanan yang kuat untuk melindungi
                                                        data sensitif Anda.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Manfaat Produk BIG-DATA Intelligence:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Keputusan yang Lebih Informatif: Dengan
                                                        wawasan yang mendalam dari analisis data,
                                                        Anda dapat mengambil keputusan yang lebih
                                                        terinformasi dan tepat sasaran.
                                                    </li>
                                                    <li>
                                                        Pengembangan Strategi: Produk ini membantu
                                                        Anda merumuskan strategi bisnis yang lebih
                                                        kuat dengan pemahaman yang lebih baik
                                                        tentang pasar dan tren.
                                                    </li>
                                                    <li>
                                                        Inovasi Berbasis Data: Mengidentifikasi
                                                        peluang baru dan tren yang muncul membantu
                                                        Anda menciptakan inovasi yang didorong oleh
                                                        data.
                                                    </li>
                                                    <li>
                                                        Efisiensi Operasional: Dengan
                                                        mengidentifikasi area yang perlu
                                                        ditingkatkan, Anda dapat meningkatkan
                                                        efisiensi operasional dan mengurangi biaya.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PageProduct;
