import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import ContactForms from '../HomeFive/ContactForm';
import FooterHomeFive from '../HomeFive/FooterHomeFive';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import Drawer from '../Mobile/Drawer';
import HeroAbout from './HeroAbout';
import SectionAbout from './SectionAbout';

function AboutUs() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <HeroAbout />
            <SectionAbout />
            <ContactForms />
            <FooterHomeFive />
            <BackToTop className="back-to-top-5" />
        </>
    );
}

export default AboutUs;
