import React from 'react';
import solusikeamanan from '../../assets/images/ckbassetbanner/solusikeamanan.webp';
import solusipemerintah from '../../assets/images/ckbassetbanner/solusipemerintah.webp';
import solusipublic from '../../assets/images/ckbassetbanner/solusipublic.webp';
import solusiswasta from '../../assets/images/ckbassetbanner/solusiswasta.webp';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function WhyChooseUs() {
    return (
        <>
            <section
                className="appie-features-area-2 why-choose-us pt-90 pb-100 pl-5 pr-5"
                id="features"
            >
                <div className="d-flex justify-content-center">
                    <div className="overlay-tag">Solusi</div>
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <h3 className="appie-title">Mengapa Memilih Kami?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={solusipublic} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="why-choose-us">
                                    <h4 className="title">SOLUSI UNTUK PUBLIK</h4>
                                    <p>
                                        Kami menekankan verifikasi identitas yang cepat, mudah, dan
                                        aman untuk semua individu. Dengan pendekatan
                                        "Fit-For-Purpose," kami menyediakan solusi biometrik yang
                                        efisien dan ekonomis untuk mengakomodasi kebutuhan identitas
                                        yang beragam.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end row reason */}
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="why-choose-us">
                                    <h4 className="title">SOLUSI UNTUK LEMBAGA PEMERINTAH</h4>
                                    <p>
                                        Solusi perangkat keras dan perangkat lunak kami memberikan
                                        alat lengkap bagi petugas penegak hukum. Laptop dan
                                        perangkat otentikasi tahan lama, handal, dan mobile.
                                        Otentikasi dan verifikasi yang akurat, hemat waktu, dan
                                        aman, mendukung kebutuhan industri keamanan.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={solusipemerintah} alt="" />
                            </div>
                        </div>
                    </div>
                    {/* end row reason */}
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={solusiswasta} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="why-choose-us">
                                    <h4 className="title">
                                        SOLUSI UNTUK LEMBAGA/PERUSAHAAN SWASTA
                                    </h4>
                                    <p>
                                        Kami menyediakan solusi inovatif dan handal untuk identitas
                                        di perusahaan swasta. Verifikasi identitas cepat, mudah, dan
                                        aman, tanpa memandang situasi. Pendekatan sesuai tujuan
                                        dengan solusi perangkat keras, perangkat lunak, dan layanan
                                        biometrik yang efisien. Siap menghadapi perubahan tuntutan
                                        identitas di dunia perusahaan.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end row reason */}
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="why-choose-us">
                                    <h4 className="title">SOLUSI UNTUK MANAJEMEN KEAMANAN</h4>
                                    <p>
                                        Solusi biometrik kami, perangkat keras dan perangkat lunak,
                                        mendukung manajemen keamanan di perusahaan swasta. Laptop
                                        dan perangkat otentikasi handal memungkinkan pengambilan
                                        data yang aman dari berbagai lokasi, meningkatkan
                                        produktivitas dan keamanan.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={solusikeamanan} alt="" />
                            </div>
                        </div>
                    </div>
                    {/* end row reason */}
                    <div className="d-flex align-items-center justify-content-center pt-50 text-read-more">
                        <a href="#">
                            <span className="mr-2">Lihat Lebih Lengkap</span>{' '}
                            <i className="fal fa-arrow-circle-right " />
                        </a>
                    </div>
                </div>
                {/* end container */}
                <div className="features-shape-1">
                    <img src={shape15} alt="" />
                </div>
                <div className="features-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="features-shape-3">
                    <img src={shape13} alt="" />
                </div>
            </section>
        </>
    );
}

export default WhyChooseUs;
