import React from 'react';

function HeroSolusi() {
    return (
        <>
            <section className="appie-about-page-area solusi">
                <div className="container">
                    <div className="d-flex justify-content-start mb-5 tag-about">
                        <div className="overlay-tag">Solusi</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-50-percent">
                            <h3 className="title">Mengapa Memilih Kami?</h3>
                        </div>
                        <div className="w-50-percent">
                            <p>
                                Solusi inovatif kami, didorong oleh pemahaman mendalam akan
                                tantangan Anda, membantu mewujudkan kesuksesan melalui kerja sama
                                yang berkelanjutan.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroSolusi;
