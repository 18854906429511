import React from 'react';
import bannerabout from '../../assets/images/ckbassetbanner/banner-2-about.png';

function SectionAbout() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={bannerabout} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <h1>PT. Cahaya Keberuntungan Bersama</h1>
                                            <p className="mt-2">
                                                PT Cahaya Keberuntungan Bersama adalah perusahaan
                                                nasional yang menyediakan layanan pengadaan
                                                elektronik, telekomunikasi, konsultasi komputer,
                                                pengadaan barang dan jasa, serta manajemen.
                                                Perusahaan ini berfokus pada visi dan misi untuk
                                                mencapai target dan keberhasilan yang berkelanjutan
                                                serta memastikan bahwa manajemen dan karyawan
                                                bekerja bersama demi kesuksesan jangka panjang.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Visi dan Misi Perusahaan</h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Menjadi perusahaan terbaik dalam pengadaan
                                                    barang dan jasa sebagai mitra pemerintah dan
                                                    swasta untuk menjadi Good Corporate Citizen
                                                    dengan standing keuangan yang solid,
                                                    kepemimpinan pasar di Indonesia dan membangun
                                                    reputasi Global sebagai produsen dan
                                                    berkualitas.
                                                </p>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Tujuan Perusahaan:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Menjadi perusahaan yang memiliki kinerja
                                                        yang baik, ditinjau dari perspektif
                                                        keuangan, pelanggan, proses internal maupun
                                                        organisasi dan sumber daya manusia.
                                                    </li>
                                                    <li>
                                                        Menjadi perusahaan yang mampu meningkatkan
                                                        kesejahteraan karyawan.
                                                    </li>
                                                    <li>
                                                        Memberikan nilai yang tinggi untuk produk
                                                        dan jasa kepada pelanggan.
                                                    </li>
                                                    <li>
                                                        Memberikan nilai kembali yang memadai atas
                                                        saham.
                                                    </li>
                                                    <li>
                                                        Turut melaksanakan dan menunjang kebijakan
                                                        dan program pemerintah di bidang ekonomi dan
                                                        pembangunan nasional pada umumnya dan
                                                        khususnya di bidang industri telekomunikasi,
                                                        elektronika dan informatika dengan
                                                        memperhatikan prinsip-prinsip yang berlaku.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SectionAbout;
