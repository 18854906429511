import React, { useState } from 'react';
import Swal from 'sweetalert2';
import contactimg from '../../assets/images/ckbassetbanner/contactus.webp';

function ContactForms() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
    });

    const [isLoading, setIsLoading] = useState(false); // State to manage loading indicator

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Display SweetAlert with a loading message
        Swal.fire({
            title: 'Mengirim Pesan',
            allowOutsideClick: false, // Prevent closing the SweetAlert
            showConfirmButton: false, // Hide the default "OK" button
            onBeforeOpen: () => {
                // Show loading indicator when SweetAlert is about to open
                setIsLoading(true);
            },
        });

        // Simulate a delay (e.g., 2 seconds) before updating the SweetAlert
        setTimeout(() => {
            // Hide loading indicator
            setIsLoading(false);

            // Replace the SweetAlert content with success message
            Swal.update({
                title: 'Pesan telah terkirim',
                text: 'Terima kasih telah menghubungi kami',
                icon: 'success',
                showConfirmButton: true, // Show the "OK" button again
            });

            // Reset the form data (optional)
            setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                message: '',
            });
        }, 2000); // Adjust the delay time in milliseconds as needed
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <section className="contact-section" id="contact">
                <div className="container-fluid">
                    <div className="d-flex justify-content-start mb-3">
                        <div className="overlay-tag">Hubungi Kami</div>
                    </div>
                    <div className="row mb-50">
                        <div className="col-md-6">
                            <div className="contact--info-area">
                                <h3>
                                    Jangan Ragu untuk Mulai <br /> Berkolaborasi Bersama!
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 title-contact-paragraph">
                            <p>
                                Hubungi Kami untuk Solusi Keamanan Terbaik. Kami siap memberikan
                                solusi yang sesuai dengan kebutuhan Anda untuk meningkatkan tingkat
                                keamanan dan keberlanjutan bisnis Anda.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={contactimg} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="contact-form">
                                <form onSubmit={handleFormSubmit} className="row">
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Nama"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Phone Number"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="company"
                                            placeholder="Company"
                                            value={formData.company}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="Beritahu kami tentang kebutuhan project Anda?"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-12 text-left">
                                        <input type="submit" name="submit" value="Kirim" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isLoading && (
                <div className="loading-indicator">
                    {/* You can add a loading animation or message here */}
                    Loading...
                </div>
            )}
        </>
    );
}

export default ContactForms;
