import React from 'react';
import osint from '../../assets/images/ckbproduct/osint.png';

function ProductCyberIntelligence() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={osint} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">08</span>
                                            <h1>CYBER INTELLIGENCE</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Cyber Intelligence
                                                kami. Produk ini adalah solusi unggulan yang kami
                                                tawarkan untuk membantu organisasi menghadapi
                                                tantangan keamanan siber dengan lebih efektif. Mari
                                                kita jelaskan lebih lanjut tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Apa itu Produk Cyber Cellular?</h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Cyber Intelligence adalah platform yang
                                                    dirancang khusus untuk membantu organisasi dalam
                                                    mengumpulkan, menganalisis, dan
                                                    menginterpretasikan data terkait dengan ancaman
                                                    keamanan siber. Dengan memanfaatkan teknologi
                                                    terkini dan metodologi analisis canggih, produk
                                                    kami membantu Anda mengidentifikasi, mengatasi,
                                                    dan mencegah potensi serangan siber yang dapat
                                                    membahayakan data dan operasi bisnis Anda.
                                                </p>
                                                <p>
                                                    Produk Cyber Intelligence kami adalah solusi
                                                    yang kuat untuk menghadapi ancaman keamanan
                                                    siber yang semakin kompleks. Dengan analisis
                                                    mendalam dan pendekatan yang proaktif terhadap
                                                    keamanan, Anda dapat melindungi data, sistem,
                                                    dan operasi bisnis Anda dari serangan siber.
                                                    Jika Anda ingin menjaga bisnis Anda tetap aman
                                                    di era digital ini, produk kami adalah pilihan
                                                    yang tepat.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Fitur Utama Produk Cyber Intelligence:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Deteksi Ancaman: Produk kami memungkinkan
                                                        Anda untuk mendeteksi dan mengidentifikasi
                                                        ancaman keamanan siber yang mungkin
                                                        mengancam sistem dan data Anda.
                                                    </li>
                                                    <li>
                                                        Analisis Mendalam: Dengan algoritma analisis
                                                        yang kuat, produk Cyber Intelligence
                                                        membantu Anda menganalisis data ancaman
                                                        dengan lebih mendalam, mengidentifikasi pola
                                                        serangan, dan memahami metode yang digunakan
                                                        oleh penyerang.
                                                    </li>
                                                    <li>
                                                        Intelligence Sharing: Kami menyediakan fitur
                                                        untuk berbagi informasi tentang ancaman
                                                        dengan komunitas keamanan siber yang lebih
                                                        luas, memungkinkan kolaborasi dalam
                                                        menghadapi serangan.
                                                    </li>
                                                    <li>
                                                        Prediksi dan Pemantauan: Produk ini dapat
                                                        membantu Anda meramalkan tren keamanan siber
                                                        masa depan dan memantau perubahan dalam
                                                        lanskap keamanan.
                                                    </li>
                                                    <li>
                                                        Keamanan Data: Keamanan data adalah
                                                        prioritas utama. Produk kami dilengkapi
                                                        dengan fitur keamanan yang kuat untuk
                                                        melindungi informasi sensitif Anda.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Manfaat Produk Cyber Intelligence:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Deteksi Dini Ancaman: Dengan mendeteksi
                                                        ancaman lebih awal, Anda dapat mengambil
                                                        tindakan yang cepat untuk mencegah serangan
                                                        sebelum mereka menyebabkan kerusakan.
                                                    </li>
                                                    <li>
                                                        Pemahaman Lebih Baik: Produk ini membantu
                                                        Anda memahami taktik dan sasaran penyerang,
                                                        sehingga Anda dapat mengembangkan strategi
                                                        pertahanan yang lebih baik.
                                                    </li>
                                                    <li>
                                                        Proteksi Data: Dengan mengidentifikasi celah
                                                        dan ancaman, Anda dapat memperkuat keamanan
                                                        data dan sistem Anda.
                                                    </li>
                                                    <li>
                                                        Reaksi Cepat: Informasi yang diberikan oleh
                                                        produk ini membantu Anda merespons serangan
                                                        dengan lebih cepat dan lebih terkoordinasi.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductCyberIntelligence;
