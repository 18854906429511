import React from 'react';
import techsurveilance from '../../assets/images/ckbproduct/techsurveilance.png';

function ProductTechnicalSurveillance() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img
                                                src={techsurveilance}
                                                alt="Technical Surveilance"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">12</span>
                                            <h1>TECHNICAL SURVEILLANCE</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Technical
                                                Surveillance kami. Produk ini merupakan solusi
                                                terkini yang kami tawarkan untuk membantu organisasi
                                                dalam mengidentifikasi dan melindungi diri dari
                                                potensi ancaman pengintaian teknis. Mari kita
                                                jelaskan lebih lanjut tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Apa itu Produk Technical Surveillance?</h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Technical Surveillance adalah platform
                                                    yang dirancang khusus untuk mendeteksi,
                                                    menganalisis, dan melindungi organisasi dari
                                                    aktivitas pengintaian teknis yang dapat
                                                    mengancam keamanan dan kerahasiaan informasi.
                                                    Produk kami menggunakan teknologi mutakhir untuk
                                                    mengidentifikasi perangkat dan metode yang
                                                    digunakan oleh pihak yang berpotensi melakukan
                                                    pengintaian.
                                                </p>
                                                <p>
                                                    Produk Technical Surveillance kami adalah solusi
                                                    canggih untuk melindungi organisasi dari ancaman
                                                    pengintaian teknis yang semakin berkembang.
                                                    Dengan deteksi dini, analisis yang mendalam, dan
                                                    langkah-langkah pencegahan, Anda dapat menjaga
                                                    keamanan informasi, operasi, dan privasi
                                                    organisasi Anda. Jika Anda mengutamakan
                                                    perlindungan terhadap pengintaian, produk kami
                                                    adalah pilihan yang tepat.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Fitur Utama Produk Technical Surveillance:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Deteksi Peralatan Mata-mata: Produk kami
                                                        memiliki kemampuan untuk mendeteksi
                                                        perangkat-perangkat pengintaian teknis yang
                                                        mungkin terpasang di sekitar lingkungan
                                                        organisasi Anda.
                                                    </li>
                                                    <li>
                                                        Pemantauan Jaringan: Dengan alat pemantauan
                                                        yang canggih, produk ini memonitor lalu
                                                        lintas jaringan dan perangkat untuk
                                                        mendeteksi aktivitas mencurigakan.
                                                    </li>
                                                    <li>
                                                        Analisis Signatur: Produk Technical
                                                        Surveillance membantu dalam menganalisis
                                                        tanda-tanda dan karakteristik yang
                                                        mengindikasikan adanya perangkat mata-mata.
                                                    </li>
                                                    <li>
                                                        Pencegahan dan Penghapusan: Kami menyediakan
                                                        solusi untuk mencegah pengintaian teknis dan
                                                        menghapus perangkat yang terdeteksi.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Manfaat Produk Technical Surveillance:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Deteksi Peralatan Mata-mata: Produk kami
                                                        memiliki kemampuan untuk mendeteksi
                                                        perangkat-perangkat pengintaian teknis yang
                                                        mungkin terpasang di sekitar lingkungan
                                                        organisasi Anda.
                                                    </li>
                                                    <li>
                                                        Pemantauan Jaringan: Dengan alat pemantauan
                                                        yang canggih, produk ini memonitor lalu
                                                        lintas jaringan dan perangkat untuk
                                                        mendeteksi aktivitas mencurigakan.
                                                    </li>
                                                    <li>
                                                        Analisis Signatur: Produk Technical
                                                        Surveillance membantu dalam menganalisis
                                                        tanda-tanda dan karakteristik yang
                                                        mengindikasikan adanya perangkat mata-mata.
                                                    </li>
                                                    <li>
                                                        Pencegahan dan Penghapusan: Kami menyediakan
                                                        solusi untuk mencegah pengintaian teknis dan
                                                        menghapus perangkat yang terdeteksi.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductTechnicalSurveillance;
