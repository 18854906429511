import React from 'react';
import geoint from '../../assets/images/ckbproduct/geoint.png';

function ProductGeoint() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={geoint} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">04</span>
                                            <h1>GEOSPATIAL INTELLIGENCE (GEOINT)</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Geospatial
                                                Intelligence (GEOINT) kami. Produk ini merupakan
                                                solusi unggulan yang kami tawarkan untuk membantu
                                                Anda memanfaatkan data geografis dan spasial dalam
                                                rangka mengambil keputusan yang lebih cerdas dan
                                                mendalam. Mari kita jelaskan lebih detail tentang
                                                produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Apa itu Produk Geospatial Intelligence (GEOINT)?
                                            </h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Geospatial Intelligence (GEOINT) adalah
                                                    platform yang didesain untuk memungkinkan Anda
                                                    mengumpulkan, menganalisis, dan memahami data
                                                    geografis dari berbagai sumber. Dengan teknologi
                                                    canggih, produk kami membantu Anda menjembatani
                                                    informasi geografis dengan wawasan bisnis,
                                                    strategi, dan pengambilan keputusan.
                                                </p>
                                                <p>
                                                    Produk Geospatial Intelligence (GEOINT) kami
                                                    adalah jendela ke dunia geografis yang mendalam.
                                                    Dengan memahami data geospatial, Anda dapat
                                                    merumuskan strategi yang lebih efektif,
                                                    mengambil keputusan yang lebih terinformasi, dan
                                                    menggali potensi dari informasi spasial. Jika
                                                    Anda ingin mengambil langkah menuju pemahaman
                                                    yang lebih baik tentang dunia fisik di sekitar
                                                    kita, produk kami adalah solusi yang sesuai
                                                    untuk Anda.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Fitur Utama Produk Geospatial Intelligence (GEOINT):
                                            </h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pemetaan dan Pengumpulan Data: Produk kami
                                                        memungkinkan Anda untuk memetakan dan
                                                        mengumpulkan data geografis dari berbagai
                                                        sumber, termasuk citra satelit, sensor, dan
                                                        peta digital.
                                                    </li>
                                                    <li>
                                                        Analisis Geospatial: Dengan algoritma
                                                        analisis yang kuat, produk GEOINT membantu
                                                        Anda menganalisis data geografis untuk
                                                        mengidentifikasi pola, tren, dan informasi
                                                        berharga lainnya.
                                                    </li>
                                                    <li>
                                                        Visualisasi 3D: Kami menyediakan visualisasi
                                                        tiga dimensi yang mendalam, memungkinkan
                                                        Anda memahami data geografis dengan cara
                                                        yang lebih interaktif.
                                                    </li>
                                                    <li>
                                                        Integrasi Data: Produk ini dapat
                                                        mengintegrasikan data geospatial dengan data
                                                        lainnya, memberikan wawasan yang lebih
                                                        lengkap tentang situasi dan lingkungan.
                                                    </li>
                                                    <li>
                                                        Keamanan Data: Keamanan data adalah
                                                        prioritas utama. Produk kami dilengkapi
                                                        dengan fitur keamanan yang kuat untuk
                                                        melindungi informasi sensitif.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Manfaat Produk Geospatial Intelligence (GEOINT)::
                                            </h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Wawasan Mendalam tentang Lokasi: Dengan
                                                        analisis data geografis, Anda dapat memahami
                                                        situasi dan konteks lingkungan secara
                                                        mendalam.
                                                    </li>
                                                    <li>
                                                        Pemahaman Pasar: Produk ini membantu Anda
                                                        mengidentifikasi peluang bisnis berdasarkan
                                                        data geografis, seperti lokasi potensial
                                                        untuk ekspansi.
                                                    </li>
                                                    <li>
                                                        Analisis Pesaing: Dengan wawasan dari data
                                                        geospatial, Anda dapat memahami strategi dan
                                                        aktivitas pesaing dalam konteks spasial.
                                                    </li>
                                                    <li>
                                                        Pengambilan Keputusan yang Dukungan: Produk
                                                        GEOINT memberikan informasi yang kritis
                                                        untuk pengambilan keputusan yang lebih
                                                        cerdas dan informasi.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductGeoint;
