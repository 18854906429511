import React from 'react';
import dataveilance from '../../assets/images/ckbproduct/dataveilance.png';

function ProductVeilance() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={dataveilance} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">02</span>
                                            <h1>DATAVEILLANCE</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Dataveillance kami.
                                                Produk Dataveillance adalah solusi inovatif yang
                                                kami tawarkan untuk membantu organisasi mengelola
                                                dan memahami data dengan lebih baik dalam era
                                                digital yang terus berkembang. Mari kami jelaskan
                                                lebih lanjut tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Apa itu Produk Dataveillance?</h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Dataveillance adalah perangkat lunak
                                                    canggih yang dirancang khusus untuk membantu
                                                    organisasi dalam pengumpulan, analisis, dan
                                                    pemantauan data dalam rangka pengawasan dan
                                                    pemahaman yang lebih mendalam. Ini merupakan
                                                    solusi yang kuat untuk mengelola data besar yang
                                                    terus mengalir dari berbagai sumber. Produk
                                                    Dataveillance adalah solusi yang kuat untuk
                                                    mengoptimalkan pengelolaan data dalam skala
                                                    besar, membantu organisasi mendapatkan wawasan
                                                    yang lebih dalam, mengamankan informasi penting,
                                                    dan mengambil keputusan yang lebih baik. Dengan
                                                    teknologi terbaru dan fitur yang kuat, produk
                                                    kami dapat membantu organisasi beradaptasi
                                                    dengan lingkungan bisnis yang semakin kompleks
                                                    dan terhubung.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Fitur Utama Produk Dataveillance:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pengumpulan Data Terpadu: Produk
                                                        Dataveillance memungkinkan Anda mengumpulkan
                                                        data dari berbagai sumber, termasuk
                                                        transaksi online, sensor, perangkat
                                                        terkoneksi, dan platform media sosial.
                                                    </li>
                                                    <li>
                                                        Analisis Tren dan Pola: Dengan menggunakan
                                                        algoritma analisis canggih, produk kami
                                                        membantu mengidentifikasi pola, tren, dan
                                                        wawasan yang tersembunyi dalam data,
                                                        memberikan informasi berharga untuk
                                                        pengambilan keputusan.
                                                    </li>
                                                    <li>
                                                        Pemantauan Real-Time: Produk ini menyediakan
                                                        pemantauan data real-time, memungkinkan Anda
                                                        untuk mendeteksi perilaku yang mencurigakan
                                                        atau tidak biasa secara cepat dan efisien.
                                                    </li>
                                                    <li>
                                                        Visualisasi Data: Melalui visualisasi yang
                                                        jelas dan informatif, produk ini membantu
                                                        Anda memahami data dengan lebih baik dan
                                                        memvisualisasikan informasi yang kompleks.
                                                    </li>
                                                    <li>
                                                        Pengamanan Data: Keamanan data adalah
                                                        prioritas utama. Produk Dataveillance
                                                        dilengkapi dengan fitur keamanan yang kuat
                                                        untuk melindungi data sensitif dari ancaman
                                                        cyber.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>Manfaat Produk Dataveillance:</h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Keamanan Lebih Baik: Dengan memantau data
                                                        secara aktif, produk ini membantu dalam
                                                        mendeteksi potensi ancaman dan pelanggaran
                                                        keamanan lebih awal.
                                                    </li>
                                                    <li>
                                                        Optimisasi Proses: Dengan analisis data yang
                                                        mendalam, Anda dapat mengidentifikasi area
                                                        yang dapat dioptimalkan dalam proses bisnis
                                                        Anda.
                                                    </li>
                                                    <li>
                                                        Pengambilan Keputusan yang Lebih Cerdas:
                                                        Wawasan yang diperoleh dari analisis data
                                                        membantu Anda membuat keputusan yang lebih
                                                        informasi dan lebih cerdas.
                                                    </li>
                                                    <li>
                                                        Personalisasi Layanan: Dengan memahami
                                                        preferensi dan perilaku pelanggan, Anda
                                                        dapat memberikan pengalaman yang lebih
                                                        disesuaikan dan relevan.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductVeilance;
