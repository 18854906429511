import React from 'react';
import osint from '../../assets/images/ckbproduct/osint.png';

function ProductOsint() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={osint} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">06</span>
                                            <h1>OPEN SOURCE INTELLIGENCE (OSINT)</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Open Source
                                                Intelligence (OSINT) kami. Produk ini adalah solusi
                                                terdepan yang kami tawarkan untuk membantu Anda
                                                mengumpulkan, menganalisis, dan memanfaatkan
                                                informasi dari sumber terbuka secara efektif. Mari
                                                kita jelaskan lebih rinci tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Apa itu Produk Open Source Intelligence (OSINT)?
                                            </h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Open Source Intelligence (OSINT) adalah
                                                    platform yang dirancang untuk membantu Anda
                                                    mengumpulkan data dan informasi dari berbagai
                                                    sumber terbuka di internet. Sumber-sumber
                                                    terbuka ini mencakup situs web, media sosial,
                                                    berita, forum, dan sumber-sumber publik lainnya.
                                                    Produk OSINT kami memungkinkan Anda menggali
                                                    wawasan berharga dari informasi yang tersedia
                                                    secara terbuka.
                                                </p>
                                                <p>
                                                    Produk Open Source Intelligence (OSINT) kami
                                                    adalah jendela Anda ke dunia informasi terbuka
                                                    di internet. Dengan kemampuan untuk
                                                    mengumpulkan, menganalisis, dan memanfaatkan
                                                    data dari sumber terbuka, Anda dapat memahami
                                                    lebih dalam, mengambil keputusan yang lebih
                                                    cerdas, dan mengidentifikasi peluang baru. Jika
                                                    Anda ingin menjelajahi dunia informasi yang luas
                                                    dan terbuka, produk kami adalah solusi yang
                                                    tepat untuk Anda.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Fitur Utama Produk Open Source Intelligence (OSINT):
                                            </h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pengumpulan Data dari Sumber Terbuka: Produk
                                                        kami memungkinkan Anda untuk mengumpulkan
                                                        informasi dari berbagai sumber terbuka di
                                                        internet, termasuk media sosial, berita,
                                                        blog, dan situs web lainnya.
                                                    </li>
                                                    <li>
                                                        Analisis Informasi: Dengan alat analisis
                                                        yang canggih, produk OSINT kami membantu
                                                        Anda menganalisis dan mengurai data yang
                                                        dikumpulkan dari sumber-sumber terbuka,
                                                        sehingga Anda dapat mengidentifikasi pola,
                                                        tren, dan wawasan penting.
                                                    </li>
                                                    <li>
                                                        Visualisasi Data: Kami menyediakan
                                                        visualisasi data yang informatif dan mudah
                                                        dimengerti, membantu Anda menggali informasi
                                                        yang berharga dengan lebih efisien.
                                                    </li>
                                                    <li>
                                                        Integrasi dengan Alat Lain: Produk ini dapat
                                                        diintegrasikan dengan alat analisis lainnya,
                                                        memberikan pandangan yang lebih
                                                        komprehensif.
                                                    </li>
                                                    <li>
                                                        Keamanan Data: Keamanan data adalah
                                                        prioritas utama kami. Produk kami
                                                        menyediakan fitur keamanan yang kuat untuk
                                                        melindungi informasi yang Anda kumpulkan.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Manfaat Produk Open Source Intelligence (OSINT):
                                            </h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pemahaman yang Mendalam: Dengan akses ke
                                                        informasi dari sumber terbuka, Anda dapat
                                                        mendapatkan pemahaman yang lebih dalam
                                                        tentang berbagai topik dan isu.
                                                    </li>
                                                    <li>
                                                        Pengambilan Keputusan yang Terinformasi:
                                                        Informasi yang diperoleh dari OSINT membantu
                                                        Anda dalam mengambil keputusan yang lebih
                                                        tepat dan berdasarkan fakta.
                                                    </li>
                                                    <li>
                                                        Pemantauan Trend: Produk OSINT membantu Anda
                                                        mengidentifikasi tren dan perubahan yang
                                                        terjadi di berbagai domain.
                                                    </li>
                                                    <li>
                                                        Analisis Pesaing: Anda dapat memantau
                                                        aktivitas pesaing dan perkembangan industri
                                                        melalui informasi terbuka yang tersedia.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductOsint;
