import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import ContactForms from '../HomeFive/ContactForm';
import FooterHomeFive from '../HomeFive/FooterHomeFive';
import HeaderHomeTwo from '../HomeTwo/HeaderHomeTwo';
import Drawer from '../Mobile/Drawer';
import HeroIndustri from './HeroIndustri';
import SectionIndustri from './SectionIndustri';

function Industri() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <HeroIndustri />
            <SectionIndustri />
            <ContactForms />
            <FooterHomeFive />
            <BackToTop className="back-to-top-5" />
        </>
    );
}

export default Industri;
