import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeFive from './components/HomeFive';
import Industri from './components/Industri';
import Adint from './components/Pages/Adint';
import BigDataInteligence from './components/Pages/BigDataInteligence';
import CyberCellular from './components/Pages/CyberCelullar';
import CyberIntelligence from './components/Pages/CyberIntelligence';
import Dataveilance from './components/Pages/DataVeilance';
import Geoint from './components/Pages/Geoint';
import Osint from './components/Pages/Osint';
import Signt from './components/Pages/Signt';
import TechnicalSurveillance from './components/Pages/TechnicalSurveillance';
import Solusi from './components/Solusi';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomeFive} />
                            <Route path="/big-data-intelligence" component={BigDataInteligence} />
                            <Route path="/data-veilance" component={Dataveilance} />
                            <Route path="/signt" component={Signt} />
                            <Route path="/geoint" component={Geoint} />
                            <Route path="/adint" component={Adint} />
                            <Route path="/osint" component={Osint} />
                            <Route path="/cyber-cellular" component={CyberCellular} />
                            <Route path="/cyber-intelligence" component={CyberIntelligence} />
                            <Route
                                path="/technical-surveillance"
                                component={TechnicalSurveillance}
                            />
                            <Route path="/industri" component={Industri} />
                            <Route path="/solusi" component={Solusi} />
                            <Route exact path="/tentang-kami" component={AboutUs} />
                            <Route exact path="/error" component={Error} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
