import React from 'react';
import imagepemerintah from '../../assets/images/ckbassetbanner/imagepemerintah.png';
import imagepublik from '../../assets/images/ckbassetbanner/imagepublik.png';
import imageswasta from '../../assets/images/ckbassetbanner/imageswasta.png';

const ContentCard = ({ imageSrc, title, content }) => (
    <section className="appie-about-area about-ckb mt-3 mb-3 ml-5 mr-5">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div
                        className="appie-about-box wow animated fadeInUp"
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-img">
                                    <img src={imageSrc} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="appie-product-content">
                                    <h1>{title}</h1>
                                    <p className="mt-2">{content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

function SectionIndustri() {
    return (
        <>
            <ContentCard
                imageSrc={imagepublik}
                title="PUBLIK"
                content="Teknologi identifikasi & verifikasi biometrik memberikan pengalaman yang aman dan efisien bagi pengunjung. Dengan layanan seperti pendaftaran akun atau akses ke area pelanggan, pengguna dapat dengan cepat mengonfirmasi identitas mereka melalui sidik jari atau pengenalan wajah. Hal ini mengurangi risiko akun yang dicuri atau disalahgunakan. Selain itu, melalui penggunaan teknologi keamanan siber yang kuat, kami memberikan keyakinan kepada pengguna bahwa informasi pribadi mereka aman dan tidak akan disalahgunakan."
            />
            <ContentCard
                imageSrc={imagepemerintah}
                title="PEMERINTAH"
                content="Mengintegrasikan solusi keamanan biometrik dan siber. Ini memungkinkan lembaga pemerintah untuk memberikan layanan online yang aman dan efisien bagi warganya. Misalnya, pengguna dapat mengajukan permohonan paspor secara online dengan menggunakan identifikasi wajah atau sidik jari, yang meningkatkan keamanan dan mengurangi risiko identitas palsu. Selain itu, melalui sistem keamanan siber yang kuat, kami melindungi data sensitif pemerintah dan menjaga keamanan operasi yang krusial."
            />
            <ContentCard
                imageSrc={imageswasta}
                title="SWASTA"
                content="Teknologi identifikasi & verifikasi biometrik membantu dalam mengamankan informasi pelanggan dan transaksi bisnis. Pengunjung dapat melakukan pembelian atau mengakses akun mereka dengan cepat melalui identifikasi sidik jari atau wajah. Kami juga menyediakan solusi keamanan siber yang dapat memberikan perlindungan terhadap serangan siber yang mungkin terjadi, menjaga keamanan data pelanggan dan informasi bisnis rahasia. Dengan kombinasi teknologi ini, kami memastikan bahwa hubungan kami dengan pelanggan dan klien berjalan lancar dan aman."
            />
        </>
    );
}

export default SectionIndustri;
