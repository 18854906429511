import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import sisteminformasi from '../../assets/images/ckbassetbanner/banner-si.webp';
import elektronika from '../../assets/images/ckbassetbanner/bannerelektronik.webp';
import perdaganganbesar from '../../assets/images/ckbassetbanner/perdaganganbesar.webp';
import sistemkeamanan from '../../assets/images/ckbassetbanner/sistemkeamanan.webp';
import shape from '../../assets/images/shape/shape-10.png';

function HeroHomeFive() {
    return (
        <>
            <section className="appie-hero-area appie-hero-4-area">
                <div className="container-fluid px-5">
                    <div className="row">
                        <div className="col-lg-5 bg-img-hero-left d-flex">
                            <div className="appie-hero-content appie-hero-content-4 d-flex flex-column justify-content-end">
                                <h1 className="appie-title text-white">
                                    Keamanan Masa Depan, Kini di Tangan Anda
                                </h1>
                                <p className="text-white">
                                    Menghadirkan solusi keamanan berkualitas untuk bisnis Anda.
                                    Mulailah lindungi bisnis Anda hari ini.
                                </p>
                                <a className="main-btn" href="#contact">
                                    Hubungi Kami
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 hero-img-right">
                            <div className="title-pt-ckb">
                                <h3>Sektor PT. Cahaya Keberuntungan Bersama</h3>
                            </div>
                            <div className="d-flex flex-wrap hero-menu-img">
                                <div className="content-hero" style={{ position: 'relative' }}>
                                    <img src={elektronika} alt="" />
                                    <p className="overlay-text">
                                        <Link to="/home-two">Elektronika</Link>
                                    </p>
                                </div>
                                <div className="content-hero" style={{ position: 'relative' }}>
                                    <img src={sisteminformasi} alt="" />

                                    <p className="overlay-text">
                                        <Link to="/home-two">Sistem Informasi</Link>
                                    </p>
                                </div>
                                <div className="content-hero" style={{ position: 'relative' }}>
                                    <img src={sistemkeamanan} alt="" />

                                    <p className="overlay-text">
                                        <Link to="/home-two">Sistem Keamanan</Link>
                                    </p>
                                </div>
                                <div className="content-hero" style={{ position: 'relative' }}>
                                    <img src={perdaganganbesar} alt="" />

                                    <p className="overlay-text">
                                        <Link to="/home-two">Perdagangan Besar</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shape} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeFive;
