import React from 'react';
import solusikeamanan from '../../assets/images/ckbassetbanner/solusikeamanan.png';
import solusipemerintah from '../../assets/images/ckbassetbanner/solusipemerintah.png';
import solusipublik from '../../assets/images/ckbassetbanner/solusipublik.png';
import solusiswasta from '../../assets/images/ckbassetbanner/solusiswasta.png';

const ContentCard = ({ imageSrc, title, content }) => (
    <section className="appie-about-area about-ckb mt-3 mb-3 ml-5 mr-5">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div
                        className="appie-about-box wow animated fadeInUp"
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-img">
                                    <img src={imageSrc} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="appie-product-content">
                                    <h1>{title}</h1>
                                    <p className="mt-2">{content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

function SectionSolusi() {
    return (
        <>
            <ContentCard
                imageSrc={solusipublik}
                title="SOLUSI UNTUK PUBLIK"
                content={`Kami menempatkan individu di pusat solusi kami dan menyediakan verifikasi identitas dan otentikasi yang cepat, mudah, dan aman - apa pun keadaannya.
Dengan pendekatan "Fit-For-Purpose", kami menawarkan solusi perangkat keras, perangkat lunak, dan layanan biometrik multimodal yang hemat biaya, tangguh, untuk memenuhi tuntutan identitas yang terus berubah dari warga.`}
            />

            <ContentCard
                imageSrc={solusipemerintah}
                title="SOLUSI UNTUK LEMBAGA PEMERINTAH"
                content={`Solusi perangkat keras dan perangkat lunak kami memastikan petugas penegak hukum memiliki perlengkapan yang lengkap untuk mengelola kebutuhan biometrik. Baik Anda sedang merekam atau mengumpulkan bukti, ketahuilah bahwa laptop dan perangkat otentikasi Anda tahan lama, handal, dan akan menjaga Anda terhubung untuk mengirim temuan dari mana pun Anda membutuhkannya.
                Otentikasi dan verifikasi yang akurat, menghemat waktu, mobile, dan aman saat bepergian merupakan kombinasi dari banyak persyaratan utama bagi mereka dalam industri keamanan.`}
            />
            <ContentCard
                imageSrc={solusiswasta}
                title="SOLUSI UNTUK LEMBAGA/PERUSAHAAN SWASTA"
                content={`Kami menawarkan solusi yang inovatif dan handal dalam mengatasi kebutuhan identitas warga di lingkungan perusahaan swasta. Dengan fokus pada individualitas, kami menyediakan verifikasi identitas dan otentikasi yang cepat, mudah, dan aman - tanpa memandang situasi.
                Dengan pendekatan yang sesuai dengan tujuan, kami menyediakan rangkaian solusi perangkat keras, perangkat lunak, dan layanan biometrik multimodal yang efisien dan kokoh. Kami siap untuk menghadapi perubahan tuntutan identitas yang berkelanjutan dari warga di dunia perusahaan.`}
            />
            <ContentCard
                imageSrc={solusikeamanan}
                title="SOLUSI UNTUK MANAJEMEN KEAMANAN"
                content={`Solusi kami, baik dalam bentuk perangkat keras maupun perangkat lunak, dirancang untuk mendukung manajemen keamanan di lingkungan perusahaan swasta. Dengan perlengkapan yang andal, Anda dapat dengan percaya diri mengelola kebutuhan biometrik. Apakah Anda sedang merekam data atau mengumpulkan informasi penting, Anda dapat mengandalkan laptop dan perangkat otentikasi kami yang kokoh dan handal. Tetap terhubung dan mengirimkan temuan dari berbagai lokasi dengan lancar.
                Kombinasi otentikasi dan verifikasi yang akurat, efisiensi waktu, mobilitas, serta tingkat keamanan yang optimal, menjadikan solusi kami sebagai jawaban bagi para profesional di industri keamanan perusahaan swasta. Dengan solusi ini, Anda dapat menjaga integritas informasi, mengoptimalkan produktivitas, dan merasa aman dalam setiap perjalanan bisnis Anda.`}
            />
        </>
    );
}

export default SectionSolusi;
