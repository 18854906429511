import React from 'react';
import { Link } from 'react-router-dom';

function MenuProduct({ className }) {
    const currentPath = window.location.pathname;
    console.log(currentPath);

    const links = [
        { id: 1, path: '/big-data-intelligence', label: 'Big Data Intelligence' },
        { id: 2, path: '/data-veilance', label: 'Data Veilance' },
        { id: 3, path: '/signt', label: 'Signal Intelligence (SIGNT)' },
        { id: 4, path: '/adint', label: 'Advertising Intelligence (ADINT)' },
        { id: 5, path: '/osint', label: 'Open Source Intelligence (OSINT)' },
        { id: 6, path: '/cyber-cellular', label: 'Cyber Cellular' },
        { id: 7, path: '/cyber-intelligence', label: 'Cyber Intelligence' },
        { id: 8, path: '/#', label: 'Data Fusion & Analysis' },
        { id: 9, path: '/#', label: 'Counter Intelligence' },
        { id: 10, path: '/#', label: 'Cyber Security' },
        { id: 11, path: '/technical-surveillance', label: 'Technical Surveillance' },
    ];

    return (
        <div className={`menu-product ${className || ''}`}>
            <div className="title">
                <h5 className="text-white">Produk</h5>
            </div>
            <div className="d-flex list-item-product">
                <div className="title-item-product">
                    <h5>Produk Terbaik Dengan Pengalaman Terbaik</h5>
                </div>
                <div className="content-item-product">
                    <ul>
                        {links.map((link) => (
                            <li key={link.id} className={currentPath === link.path ? 'active' : ''}>
                                <Link
                                    to={link.path}
                                    className={currentPath === link.path ? 'active' : ''}
                                >
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuProduct;
