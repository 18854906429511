import React from 'react';
import adint from '../../assets/images/ckbproduct/adint.png';

function ProductAdint() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={adint} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-product-content">
                                            <span className="number-service-product mb-3">05</span>
                                            <h1>ADVERTISING INTELLIGENCE (ADINT)</h1>
                                            <p className="mt-2">
                                                Selamat datang di halaman produk Advertising
                                                Intelligence (ADINT) kami. Produk ini adalah solusi
                                                terkini yang kami tawarkan untuk membantu Anda dalam
                                                mengoptimalkan kampanye iklan dan memaksimalkan
                                                dampak strategi pemasaran Anda. Mari kita jelaskan
                                                lebih rinci tentang produk ini:
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Apa itu Produk Advertising Intelligence (ADINT)?
                                            </h3>
                                            <div className="pr-5 mt-2">
                                                <p>
                                                    Produk Advertising Intelligence (ADINT) adalah
                                                    platform yang dirancang untuk memungkinkan Anda
                                                    mengumpulkan, menganalisis, dan mendapatkan
                                                    wawasan dari data terkait iklan dan pemasaran.
                                                    Dengan teknologi canggih, produk kami membantu
                                                    Anda mengukur efektivitas kampanye iklan,
                                                    memahami perilaku konsumen, dan mengoptimalkan
                                                    strategi pemasaran Anda.
                                                </p>
                                                <p>
                                                    Produk Advertising Intelligence (ADINT) kami
                                                    adalah panduan Anda dalam menghadapi dunia iklan
                                                    yang semakin kompleks. Dengan kemampuan untuk
                                                    menganalisis data iklan dan memahami perilaku
                                                    konsumen, Anda dapat merancang kampanye yang
                                                    lebih cerdas, mengoptimalkan pengeluaran iklan,
                                                    dan meningkatkan dampak strategi pemasaran Anda
                                                    secara keseluruhan. Jika Anda ingin mengambil
                                                    langkah menuju kesuksesan iklan yang lebih
                                                    besar, produk kami adalah solusi yang tepat
                                                    untuk Anda.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                                <div className="row mt-5">
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Fitur Utama Produk Advertising Intelligence (ADINT):
                                            </h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Pemantauan Kampanye: Produk kami
                                                        memungkinkan Anda untuk memantau dan
                                                        menganalisis kinerja kampanye iklan Anda di
                                                        berbagai platform, termasuk media sosial,
                                                        iklan online, dan lainnya.
                                                    </li>
                                                    <li>
                                                        Analisis Efektivitas: Dengan alat analisis
                                                        yang canggih, produk ADINT membantu Anda
                                                        mengukur sejauh mana iklan Anda mencapai
                                                        tujuan pemasaran dan bisnis Anda.
                                                    </li>
                                                    <li>
                                                        Pemahaman Konsumen: Kami menyediakan wawasan
                                                        mendalam tentang perilaku konsumen,
                                                        preferensi, dan interaksi mereka dengan
                                                        iklan Anda.
                                                    </li>
                                                    <li>
                                                        Kompetitor Analysis: Produk ini dapat
                                                        membantu Anda memahami strategi pemasaran
                                                        pesaing Anda, memberikan keunggulan dalam
                                                        kompetisi.
                                                    </li>
                                                    <li>
                                                        Visualisasi Data: Kami menyediakan
                                                        visualisasi yang jelas dan intuitif,
                                                        membantu Anda memahami data pemasaran dengan
                                                        lebih baik.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex mt-3 product-ckb flex-column">
                                            <h3>
                                                Manfaat Produk Advertising Intelligence (ADINT):
                                            </h3>
                                            <div className="list-in-product pr-3 mt-3">
                                                <ul>
                                                    <li>
                                                        Peningkatan Kampanye Iklan: Dengan informasi
                                                        yang mendalam, Anda dapat mengoptimalkan
                                                        kampanye iklan Anda untuk mencapai hasil
                                                        yang lebih baik.
                                                    </li>
                                                    <li>
                                                        Penghematan Anggaran: Dengan analisis yang
                                                        akurat, Anda dapat mengalokasikan anggaran
                                                        iklan Anda dengan lebih efektif, menghindari
                                                        pemborosan.
                                                    </li>
                                                    <li>
                                                        Strategi Pemasaran yang Tepat Sasaran:
                                                        Produk ini membantu Anda merumuskan strategi
                                                        pemasaran yang lebih efektif berdasarkan
                                                        wawasan data yang kuat.
                                                    </li>
                                                    <li>
                                                        Pemahaman yang Mendalam: Dengan analisis
                                                        data iklan, Anda dapat memahami bagaimana
                                                        konsumen berinteraksi dengan merek Anda.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end list */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductAdint;
