import React from 'react';

function HeroIndustri() {
    return (
        <>
            <section className="appie-about-page-area industri">
                <div className="container">
                    <div className="d-flex justify-content-start mb-5 tag-about">
                        <div className="overlay-tag">Industri</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-50-percent">
                            <h3 className="title">
                                Ranah Industri Pengguna <br />
                                Solusi Perusahaan
                            </h3>
                        </div>
                        <div className="w-50-percent">
                            <p>
                                Pelanggan kami, termasuk perusahaan besar, start-up, dan organisasi
                                pemerintah, mengandalkan keamanan kami untuk melindungi data penting
                                mereka.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroIndustri;
