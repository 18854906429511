import React from 'react';
import aboutckb from '../../assets/images/ckbassetbanner/ckb.webp';

function AboutCkb() {
    return (
        <>
            <section className="appie-about-area about-ckb mb-100 mt-50 ml-5 mr-5">
                <div className="container-fluid">
                    <div className="d-flex justify-content-start mb-3">
                        <div className="overlay-tag">Tentang Kami</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-img">
                                            <img src={aboutckb} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-about-content">
                                            <h3 className="title">
                                                PT. Cahaya Keberuntungan Bersama
                                            </h3>
                                            <p>
                                                Podomoro City Ruko Garden Shopping Arcade Blok B 8 –
                                                D/H Jalan S. Parman, Jakarta Barat - DKI Jakarta
                                            </p>
                                        </div>
                                        <div className="d-flex mt-3 about-ckb">
                                            <div className="pr-5">
                                                <p>
                                                    PT Cahaya Keberuntungan Bersama adalah
                                                    perusahaan nasional yang menyediakan layanan
                                                    pengadaan elektronik, telekomunikasi, konsultasi
                                                    komputer, pengadaan barang dan jasa, serta
                                                    manajemen. Perusahaan ini berfokus pada visi dan
                                                    misi untuk mencapai target dan keberhasilan yang
                                                    berkelanjutan serta memastikan bahwa manajemen
                                                    dan karyawan bekerja bersama demi kesuksesan
                                                    jangka panjang.
                                                </p>
                                            </div>
                                            <div className="text-read-more-blue mt-50">
                                                <a href="#">
                                                    <span className="mr-2">
                                                        Lihat Lebih Lengkap
                                                    </span>
                                                    <i className="fal fa-arrow-circle-right " />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutCkb;
